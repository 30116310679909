import React from 'react';
import {
  FormLayout,
  TextField,
  Button,
  Icon,
  ButtonGroup,
  LegacyStack,
  RadioButton,
  Link,
  Text,
} from "@shopify/polaris";
import { TextFontIcon, TextAlignCenterIcon, TextAlignLeftIcon, TextAlignRightIcon } from "@shopify/polaris-icons";
import CardDivider from '../../../../components/cardDivider';
import update from "immutability-helper"
import styled from "styled-components"

const TextSizeWrapper = styled.div`
  .extraLarge-size svg {
    width: 130%;
    height: 130%;
    max-width: 130%;
    max-height: 130%;
    margin-top: -5px;
  }
  .medium-size .Polaris-Icon {
    padding-top: 2px;
    svg {
      margin-left: 3px;
      width: 90%;
      height: 90%;
      max-width: 90%;
      max-height: 90%;
    }
  }
  .base-size .Polaris-Icon {
    padding-top: 4px;
    svg {
      margin-left: 3px;
      width: 70%;
      height: 70%;
      max-width: 70%;
      max-height: 70%;
    }
  }
  .small-size .Polaris-Icon {
    padding-top: 5px;
    svg {
      margin-left: 4px;
      width: 60%;
      height: 60%;
      max-width: 60%;
      max-height: 60%;
    }
  }
  .extraSmall-size .Polaris-Icon {
    padding-top: 6px;
    svg {
      margin-left: 4px;
      width: 50%;
      height: 50%;
      max-width: 50%;
      max-height: 50%;
    }
  }
`
export default function BannerContent({ contentEdit, state, setState }) {
  const onImageChange = (img) => {
    setState(
      update(state, {contents: {
        [contentEdit]: { img: { $set: img.image.originalSrc } },
      }})
    )
  }
  const content = state.contents[contentEdit]
  return (
    <FormLayout>
      <h6>
        <Text  variant="bodyMd" as="span" fontWeight="semibold">Banner title</Text>
      </h6>
      <TextField
        label="Banner title"
        value={content.bannerTitle}
        onChange={(value) => {
          setState(
            update(state, {contents: {
              [contentEdit]: { bannerTitle: { $set: value } },
            }})
          )
        }}
        labelHidden
      />
      <CardDivider/>
      <h6>
        <Text  variant="bodyMd" as="span" fontWeight="semibold">Banner text</Text>
      </h6>
      <p style={{marginTop:'-1.5rem'}}>
        You can use markdown such as links.{" "}
        <Link
          external
          url="https://help.upsellplus.com/en/article/how-to-add-links-and-markdown-in-text-customizations-1xoxxi"
          target="_blank"
        >
          Learn more
        </Link>
      </p>
      <TextField
        label="Banner text"
        value={content.bannerText}
        onChange={(value) => {
          setState(
            update(state, {contents: {
              [contentEdit]: { bannerText: { $set: value } },
            }})
          )
        }}
        multiline={4}
        autoComplete="off"
        labelHidden
      />
      <CardDivider/>
      <p>
        Alignment
      </p>
      <ButtonGroup variant="segmented">
        <Button
          pressed={"start" === content.alignment}
          onClick={() => {
            setState(
              update(state, {contents: {
                [contentEdit]: { alignment: { $set: "start" } },
              }})
            )
          }}
        >
          <Icon source={TextAlignLeftIcon} tone="base" />
        </Button>
        <Button
          pressed={"center" === content.alignment}
          onClick={() => {
            setState(
              update(state, {contents: {
                [contentEdit]: { alignment: { $set: "center" } },
              }})
            )
          }}
        >
          <Icon source={TextAlignCenterIcon} tone="base" />
        </Button>
        <Button
          pressed={"end" === content.alignment}
          onClick={() => {
            setState(
              update(state, {contents: {
                [contentEdit]: { alignment: { $set: "end" } },
              }})
            )
          }}
        >
          <Icon source={TextAlignRightIcon} tone="base" />
        </Button>
      </ButtonGroup>
      <CardDivider/>
      <p>
        Size
      </p>
      <TextSizeWrapper>
        <ButtonGroup variant="segmented">
          <div className="extraLarge-size">
            <Button
              pressed={"extraLarge" === content.size}
              onClick={() => {
                setState(
                  update(state, {contents: {
                    [contentEdit]: { size: { $set: "extraLarge" } },
                  }})
                )
              }}
            >
              <Icon source={TextFontIcon} tone="base" />
            </Button>
          </div>
          <div className="large-size">
            <Button
              pressed={"large" === content.size}
              onClick={() => {
                setState(
                  update(state, {contents: {
                    [contentEdit]: { size: { $set: "large" } },
                  }})
                )
              }}
            >
              <Icon source={TextFontIcon} tone="base" />
            </Button>
          </div>
          <div className="medium-size">
            <Button
              pressed={"medium" === content.size}
              onClick={() => {
                setState(
                  update(state, {contents: {
                    [contentEdit]: { size: { $set: "medium" } },
                  }})
                )
              }}
            >
              <Icon source={TextFontIcon} tone="base" />
            </Button>
          </div>
          <div className="base-size">
            <Button
              pressed={"base" === content.size}
              onClick={() => {
                setState(
                  update(state, {contents: {
                    [contentEdit]: { size: { $set: "base" } },
                  }})
                )
              }}
            >
              <Icon source={TextFontIcon} tone="base" />
            </Button>
          </div>
          <div className="small-size">
            <Button
              pressed={"small" === content.size}
              onClick={() => {
                setState(
                  update(state, {contents: {
                    [contentEdit]: { size: { $set: "small" } },
                  }})
                )
              }}
            >
              <Icon source={TextFontIcon} tone="base" />
            </Button>
          </div>
          <div className="extraSmall-size">
            <Button
              pressed={"extraSmall" === content.size}
              onClick={() => {
                setState(
                  update(state, {contents: {
                    [contentEdit]: { size: { $set: "extraSmall" } },
                  }})
                )
              }}
            >
              <Icon source={TextFontIcon} tone="base" />
            </Button>
          </div>
        </ButtonGroup>
      </TextSizeWrapper>
      <CardDivider/>
      <p>
        Color
      </p>
      <LegacyStack>
        <RadioButton
          label="Info"
          checked={content.color === "info"}
          id="info"
          name="color"
          onChange={(value) => {
            setState(
              update(state, {contents: {
                [contentEdit]: { color: { $set: "info" } },
              }})
            )
          }}
        />
        <RadioButton
          label="Warning"
          checked={content.color === "warning"}
          id="warning"
          name="color"
          onChange={(value) => {
            setState(
              update(state, {contents: {
                [contentEdit]: { color: { $set: "warning" } },
              }})
            )
          }}
        />
        <RadioButton
          label="Success"
          checked={content.color === "success"}
          id="success"
          name="color"
          onChange={(value) => {
            setState(
              update(state, {contents: {
                [contentEdit]: { color: { $set: "success" } },
              }})
            )
          }}
        />
        <RadioButton
          label="Critical"
          checked={content.color === "critical"}
          id="critical"
          name="color"
          onChange={(value) => {
            setState(
              update(state, {contents: {
                [contentEdit]: { color: { $set: "critical" } },
              }})
            )
          }}
        />
      </LegacyStack>
      <CardDivider/>
      <p>
        Style
      </p>
      <ButtonGroup variant="segmented">
        <Button
          pressed={"bold" === content.emphasis}
          onClick={(value) => {
            if("bold" === content.emphasis) {
              setState(
                update(state, {contents: {
                  [contentEdit]: { emphasis: { $set: null } },
                }})
              )
            }else{
              setState(
                update(state, {contents: {
                  [contentEdit]: { emphasis: { $set: "bold" } },
                }})
              )
            }
          }}
        >
          B
        </Button>
        <Button
          pressed={"italic" === content.emphasis}
          onClick={(value) => {
            if("italic" === content.emphasis) {
              setState(
                update(state, {contents: {
                  [contentEdit]: { emphasis: { $set: null } },
                }})
              )
            }else{
              setState(
                update(state, {contents: {
                  [contentEdit]: { emphasis: { $set: "italic" } },
                }})
              )
            }
          }}
        >
          I
        </Button>
      </ButtonGroup>
    </FormLayout>
  );
}
